import React from "react"
import { Helmet } from "react-helmet"
import { Provider } from "react-redux"
import "./tailwind.css"
// import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

import configureStore from "./Redux/configureStore"
import Container from "./View"

const App = () => {
  const store = configureStore()
  return (
    <Provider store={store}>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>E-Maintenance </title>
        </Helmet>
        <Container />
      </div>{" "}
    </Provider>
  )
}

export default App
