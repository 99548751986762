import React from "react"
import { useHistory, Link } from "react-router-dom"
import Profile from "../Components/Profile"
import { useSelector } from "react-redux"
import InputMileage from "../Components/InputMileage"
// import api from "../../Config/localApi";

export default function Home({ props }) {
  const me = useSelector((state) => state.me)

  console.log("me", me)
  // vehicle can let from me?.driver?.vehicle?._id
  /* save Data 

  feild on vehicle "mileage"
   await api
    .put(`${process.env.REACT_APP_API_URL}/vehicle/${me?.driver?.vehicle?._id}`, payload)
    .then((res) => {
   
    })
    .catch((err) => {
      console.error("Error", err);
     
    });
  
  
  */

  const history = useHistory()
  return (
    <div className="container mx-auto">
      <div>
        <Profile userData={me?.userData} />
        <InputMileage />
      </div>

      <div className="flex flex-col gap-2">
        <button
          className="bg-green-500 shadow-md hover:shadow-lg  text-lg py-6 text-shamrock-50  font-sans p-2 m-2"
          onClick={() => {
            history.push("/about")
          }}
        >
          About
        </button>

        <button
          className="bg-red-500 shadow-md hover:shadow-lg  text-lg py-6 text-shamrock-50  font-sans p-2 m-2"
          onClick={() => {
            window.localStorage.removeItem("LOCAL_TOKEN")
            window.localStorage.removeItem("USER_ID")
            window.localStorage.removeItem("USER_ID")
            window.localStorage.removeItem(
              "LIFF_STORE:1661016563-Lo3EbbzW:context"
            )
            window.localStorage.removeItem(
              "LIFF_STORE:1661016563-Lo3EbbzW:accessToken"
            )
            window.localStorage.removeItem(
              "LIFF_STORE:1661016563-Lo3EbbzW:clientId"
            )
            window.location.reload()
          }}
        >
          Logout
        </button>
      </div>
    </div>
  )
}
