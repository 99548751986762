import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import liff from "@line/liff";

import Home from "./Home";
import About from "./About";

import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";

import * as actions from "../Redux/Actions";
import { Result, Spin } from "antd";

const Container = () => {
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(false);
  const me = useSelector((state) => state.me);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    liff
      .init({ liffId: process.env.REACT_APP_LIFF_ID })
      .then(() => {
        console.log("Connect to liff");
        const isLoggedIn = liff.isLoggedIn();
        if (!isLoggedIn) {
          liff.login();
        } else {
          liff.getProfile().then((profile) => {
            const payload = {
              lineUUID: profile?.userId,
              name: profile?.displayName,
              profile: profile?.pictureUrl,
            };
            dispatch(actions.meLogin(payload))
              .then(() => {
                setIsLoggedIn(true);
              })
              .catch((err) => {
                setIsReady(false);
              });
          });
        }
      })
      .catch((err) => {
        console.error("Cannot Connect to Line Frontend Framework", err);
      });
    return () => {};
  }, []);

  useEffect(() => {
    if (isLoggedIn && !isReady) {
      dispatch(actions.meGet()).then(() => {
        setIsReady(true);
      });
    }

    return () => {};
  }, [isReady, isLoggedIn]);

  if (!isReady) {
    return (
      <div className='flex justify-center'>
        <Spin size='large' />
      </div>
    );
  }

  return (
    <Router>
      <Navbar />
      <div className='min-h-screen m-8'>
        <Switch>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />{" "}
    </Router>
  );
};

export default Container;
