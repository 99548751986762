import React from "react";
import dayjs from "dayjs";
export default function Footer() {
  return (
    <footer className='w-full bg-gray-100 pt-6 pb-9 mt-3 text-center justify-center '>
      <div className='content has-text-centered'>
        <p>
          &copy; {dayjs().format("YYYY")}{" "}
          <strong>Intelligent Automation Research Center</strong> All Right
          Reserved
        </p>
      </div>
    </footer>
  );
}
