import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { useSelector, useDispatch } from "react-redux"

import * as actions from "../Redux/Actions"

import api from "../Config/localApi"

export default function InputMileage() {
  // const { handleSubmit, control } = useForm()
  const me = useSelector((state) => state.me)
  // const room = useSelector((state) => state.room)

  const [mileNumber, setMileNumber] = useState()

  const dispatch = useDispatch()

  console.log("me in input page", me)
  console.log("mileNumber in input page", mileNumber)

  useEffect(() => {
    dispatch(actions.meGet())
    // dispatch(actions.getAllRoom())
    return () => {}
  }, [])

  useEffect(() => {
    setMileNumber(me?.userData?.driver?.vehicle?.mileage)
    return () => {}
  }, [])

  const putMilleage = async () => {
    console.log("data in putmilleage", mileNumber)

    await api
      .put(
        `${process.env.REACT_APP_API_URL}/vehicle/${me?.userData?.driver?.vehicle?._id}`,
        { mileage: mileNumber }
      )
      .then((res) => {
        console.log("Data", res.data)
      })
      .catch((err) => {
        console.error("Error", err)
      })
  }

  return (
    <div className=" relative max-w-md mx-auto md:max-w-2xl mt-6 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl mt-16 flex justify-center">
      <div className="px-6 py-4">
        <div className=" flex justify-center text-md py-2 font-bold">
          เลขไมล์รถปัจจุบัน
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              label="เลขไมล์รถปัจจุบัน "
              fullWidth
              size={"medium"}
              type="number"
              defaultValue={mileNumber ? mileNumber : 0}
              value={mileNumber}
              onChange={(event) => setMileNumber(event.target.value)}
            />
          </div>
          <div className="flex justify-center">
            <Button variant="contained" onClick={() => putMilleage()}>
              บันทึก
            </Button>
          </div>
        </Box>
      </div>
    </div>
  )
}
