import React from "react";

function Profile({ userData }) {
  return (
    <div>
      <div className='relative max-w-md mx-auto md:max-w-2xl mt-6 min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl mt-16'>
        <div className='px-6'>
          <div className='flex flex-wrap justify-center'>
            <div className='w-full flex justify-center'>
              <div className='relative'>
                <img
                  src={userData?.image?.url}
                  className='shadow-xl rounded-full align-middle border-none w-24'
                />
              </div>
            </div>
          </div>
          <div className='text-center mt-2'>
            <h3 className='text-2xl text-slate-700 font-bold leading-normal mb-1'>
              {userData?.firstname} {userData?.lastname}
            </h3>
            <div className='text-xs mt-0 mb-2 text-slate-400 font-bold uppercase'>
              <i className='fas fa-map-marker-alt mr-2 text-slate-400 opacity-75'></i>
              {userData?.department?.name}
            </div>
          </div>
          <div className='mt-6 py-6 border-t border-slate-200 text-center'>
            <div className='flex flex-wrap justify-center'>
              <div className='w-full px-4'>
                <p className='font-light leading-relaxed text-slate-600 mb-4'>
                  เลขทะเบียนรถ {userData?.driver?.vehicle?.licence_number}
                </p>
                <p className='font-light leading-relaxed text-slate-600 mb-4'>
                  เลขไมล์รถปัจจุบัน {userData?.driver?.vehicle?.mileage}
                </p>
                <a
                  href='javascript:;'
                  className='font-normal text-slate-700 hover:text-slate-400'
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
