import React from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
export default function About({ props }) {
  const history = useHistory()
  const me = useSelector((state) => state.me)
  return (
    <div className="container ">
      <div class=" rounded overflow-hidden shadow-lg my-full ">
        <div class="px-6 py-4">
          <div className=" w-full flex justify-center">
            <img
              src={me?.userData?.image?.url}
              className="shadow-xl rounded-full align-middle border-none w-40"
            />
          </div>
          <br/>
          <div className="text-lg p-6 border-t-2">
            ชื่อ:{me?.userData?.firstname}
            <br />
            แผนก:{me?.userData?.department?.name}
            <br />
            เลขทะเบียนรถ: {me?.userData?.driver?.vehicle?.licence_number}
          </div>
        </div>
      </div>
    </div>
  )
}
